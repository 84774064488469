<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('elearning_tpm.trainer_sorting') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="6" sm="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="circular_memo_no"
              >
              <template v-slot:label>
                {{$t('elearning_iabm.circular_memo_no')}}
              </template>
              <b-form-input
                id="circular_memo_no"
                v-model.lazy="search.circular_memo_no"
                :placeholder="$t('elearning_iabm.circular_memo_no')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="fiscal_year_id"
            >
            <template v-slot:label>
              {{$t('elearning_config.fiscal_year')}}
            </template>
            <v-select name="organization"
              v-model="search.fiscal_year_id"
              label="text"
              :reduce="item => item.value"
              :options= fiscalYearList
              :placeholder="$t('globalTrans.select')"
            />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('elearning_config.organization')"
              label-for="organization"
            >
            <v-select name="organization"
              v-model="search.org_id"
              label="text"
              :reduce="item => item.value"
              :options= orgList
              :placeholder="$t('globalTrans.select')"
            />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="training_type_id"
            >
              <template v-slot:label>
                {{$t('elearning_config.training_type')}}
              </template>
              <b-form-select
                plain
                v-model="search.training_type_id"
                :options="trainingTypeList"
                id="training_type_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="training_category_id"
              >
              <template v-slot:label>
                {{$t('elearning_config.training_category')}}
              </template>
              <b-form-select
                plain
                v-model="search.training_category_id"
                :options="trainingCategoryList"
                id="training_category_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="training_title_id"
              >
              <template v-slot:label>
                {{$t('elearning_config.training_title')}}
              </template>
              <b-form-select
                plain
                v-model="search.training_title_id"
                :options="trainingTitleList"
                id="training_title_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" sm="12" class="text-right">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </card>
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_tpm.trainer_sorting') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link to="trainer-sorting-form" class="btn btn-add">
          <i class="fas fa-plus"></i> {{ $t('globalTrans.add_new') }}
        </router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper">
                <div class="quick-filter-left">
                  <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                    <b-dropdown-form>
                      <div
                        class="form-group"
                        v-for="(field, index) in labelData"
                        :key="index"
                        >
                        <b-form-checkbox
                          :id="'checkbox-' + field.label"
                          v-model="field.show"
                          :name="'checkbox-' + field.label"
                          value=1
                          unchecked-value=0
                        >
                        {{ ($i18n.locale==='bn') ? field.label : field.label }}
                        </b-form-checkbox>
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                  <b-form-select
                    id="per-page-select"
                    v-model="search.limit"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(type_name)="data">
                  <span class="capitalize">{{ data.item.type_name }}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                    <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingApplicationList, courseManagmentToggleStatus, courseManagmentFinalSave } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Store from '@/store'

export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      sortBy: '',
      sortDirection: '',
      sortDesc: '',
      search: {
        org_id: [],
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        sort_status: 2,
        registration_for: 1,
        fiscal_year_id: [],
        limit: 30
      },
      trainingCategoryList: [],
      trainingTitleList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
        { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
        { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
        { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
        { labels: 'elearning_config.training_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
        { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
        { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
        { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
      ]
    }
  },
  computed: {
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'circular_memo_no' },
          { key: 'fiscal_year_bn' },
          { key: 'org_bn' },
          { key: 'training_type_bn' },
          { key: 'training_title_bn' },
          { key: 'name_bn' },
          { key: 'mobile' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'circular_memo_no' },
          { key: 'fiscal_year' },
          { key: 'org' },
          { key: 'training_type' },
          { key: 'training_title' },
          { key: 'name' },
          { key: 'mobile' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    }
  },
  async created () {
    this.search = Object.assign({}, this.search, {
      // org_id: this.$store.state.dataFilters.orgId,
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    this.loadData()
  },
  watch: {
    'search.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'search.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    finalSave (item) {
      this.toggleStatusCustom2(trainingElearningServiceBaseUrl, courseManagmentFinalSave, item)
    },
    toggleStatusCustom2 (baseUrl, uri, item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusCustom(baseUrl, uri, item)
        }
      })
    },
    changeStatusCustom (baseUrl, uri, item) {
      Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    dataChange () {
      this.loadData()
    },
    async searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, courseManagmentToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
          this.paginationData(response.data, this.search.limit)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getCustomDataList (data) {
      const listData = data.map((item, index) => {
        const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }
        const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
        const officeData = {}
        if (typeof officeObj !== 'undefined') {
          officeData.office = officeObj.text_en
          officeData.office_bn = officeObj.text_bn
        } else {
          officeData.office = ''
          officeData.office_bn = ''
        }

        const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        const fiscalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
        const OfficeTypeData = {}
        if (typeof OfficeTypeListObj !== 'undefined') {
          OfficeTypeData.office_type = OfficeTypeListObj.text_en
          OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
        } else {
          OfficeTypeData.office_type = ''
          OfficeTypeData.office_type_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const trainingCategoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          trainingCategoryData.training_category = trainingCategoryObj.text_en
          trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          trainingCategoryData.training_category = ''
          trainingCategoryData.training_category_bn = ''
        }
        return Object.assign({}, { serial: index }, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData)
      })
      return listData
    }
  }
}
</script>
